import { authentication, autologin, failed, logout, startLogin } from '.';
import { ApiRequest } from '../../../Utils/ApiRequest';
import { isExpired, decodeToken } from 'react-jwt';

export const authenticationUser = ({ email, password }) => {
  return async (dispatch, getState) => {
    dispatch(startLogin());
    try {
      // TODO: Realizar petición HTTP del login
      let res = await ApiRequest.publicPost('/users/login', {
        email: email,
        password: password,
      });

      let token = res.data.token;

      localStorage.setItem('user', token);
      const myDecodedToken = decodeToken(token);
      dispatch(
        authentication({
          userLogin: token,
          name: `${res.data.user.firstname} ${res.data.user.lastname}`,
          email: email,
          id: res.data.user.id,
          isSuperAdmin: myDecodedToken.superadmin === '1',
          clients: myDecodedToken.clients || [],
        })
      );
    } catch (err) {
      dispatch(
        failed({
          message: err.status === 'Data Failure' ? 'Correo y/o contraseña NO Valido' : err.data.message,
        })
      );
    }
  };
};

export const autoLoginUser = () => {
  return async (dispatch, getState) => {
    dispatch(startLogin());
    // TODO: Realizar petición HTTP de auto login, validando el jwt
    let user = localStorage.getItem('user') || sessionStorage.getItem('user');

    if (user) {
      // verify a token symmetric - synchronous
      const myDecodedToken = decodeToken(user);
      const isMyTokenExpired = isExpired(user);
      if (isMyTokenExpired) {
        dispatch(logout());
      } else {
        dispatch(
          autologin({
            userLogin: user,
            name: `${myDecodedToken.firstname} ${myDecodedToken.lastname}`,
            email: myDecodedToken.email,
            id: myDecodedToken.id,
            isSuperAdmin: myDecodedToken.superadmin === '1',
            clients: myDecodedToken.clients || [],
          })
        );
      }
    } else {
      dispatch(logout());
    }
  };
};
export const logoutUser = () => {
  return async (dispatch, getState) => {
    // TODO: Realizar petición HTTP de auto login, validando el jwt
    localStorage.removeItem('user') || sessionStorage.removeItem('user');
    dispatch(logout());
  };
};
